import { useEffect } from 'react'
import '../styles/index.css'
import '@avcan/crystal/styles'
import Head from 'components/Head'
import * as Analytics from 'services/analytics'
import { Fallback } from 'layouts/pages'
import { Boundary as ErrorBoundary } from 'components/error'
import * as UserSettings from 'contexts/usersettings'
import * as Polyfills from 'services/polyfills'
import { identity } from '@avcan/utils/function'
import * as Authentication from 'contexts/authentication'
import * as Internationalization from 'contexts/internationalization'
import Crystal from '@avcan/crystal/components/Crystal'
import mixpanel from 'mixpanel-browser'

export default function Application({ Component, pageProps }) {
    const { getLayout = identity } = Component
    const children = getLayout(<Component {...pageProps} />)

    Analytics.useService()
    Polyfills.usePolyfills()

    useEffect(() => {
        mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '', {
            // Enable these in DEV mode to see console entries for Mixpanel requests
            // debug: true,
            // ignore_dnt: true,
        })

        process.env.NEXT_PUBLIC_MIXPANEL_TOKEN && mixpanel.register({ platform: 'website' })
    }, [])

    return (
        <Crystal>
            <Internationalization.Provider>
                <Head />
                <Authentication.Provider>
                    <ErrorBoundary fallback={<Fallback />}>
                        <UserSettings.Provider>{children}</UserSettings.Provider>
                    </ErrorBoundary>
                </Authentication.Provider>
                <NoScript />
                <Analytics.Script />
            </Internationalization.Provider>
        </Crystal>
    )
}

// Util components
function NoScript() {
    return (
        <noscript>
            <h1>Avalanche Canada</h1>
            <p>
                For full functionality of this site, it is necessary to enable JavaScript. Here are
                the
                <a href="https://www.enable-javascript.com/">
                    instructions how to enable JavaScript in your web browser
                </a>
                .
            </p>
        </noscript>
    )
}
